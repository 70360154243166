import React from "react"
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import '../../common/styles/custom.tachyons.css'
import 'tachyons';
import Layout from "../../common/layouts";

export default (props) => (
  <React.Fragment>
    <Img
      className="w-100 vh-50 mw9 center"
      fluid={props.image}
      alt={props.description} />
    <div
      className="h-auto bg-near-white mw9 w-100 flex flex-column items-center justify-center pv5 ph2 center">
      <span className="fw1 logo dark-gray db tc w-100 mw7 f1">{props.title}</span>

        <p className="serif mw6 tc f4 dn dib-l mb4 db">{props.description}</p>
        <a target='_blank' href='https://square.site/book/LSZB673B8EBPK/fusion-brow-bar-suffern-ny' className='db pv3 ph5 tracked ttu b bg-washed-red dark-gray sans-serif no-underline hover-gray'>
            Make An Appointment
        </a>


        <div
            className="h-auto bg-near-white mw9 w-100 flex flex-column items-center justify-center pv2 center">
            <Link target='_blank' to='/services' className='db pv3 ph5 tracked ttu b bg-washed-red dark-gray sans-serif no-underline hover-gray'>
                 Our Services
            </Link>

        </div>
        {/*<Link className="db pv3 ph5 tracked ttu b bg-washed-red dark-gray sans-serif no-underline hover-gray" to={props.to}>Read More</Link>*/}
    </div>
  </React.Fragment>
)
