import React from "react"
import Layout from '../common/layouts';
import {graphql} from 'gatsby';
import Hero from '../homepage/components/hero';
import Card from '../homepage/components/card';
import Seo from '../common/seo';
import { FaSearchLocation } from "@react-icons/all-files/fa/FaSearchLocation";

export default ({data}) => {
    let post = data.featuredPost.edges[0].node;
    return (
        <Layout>
            <Seo
                title={"Home Page"}
                description='Top eyebrow threading and beauty salon in Suffern, NY'/>
            <Hero
                title='Fusion Brow Bar'
                image={data.banner.childImageSharp.fluid}
                // to={post.frontmatter.slug}

                description='At Fusion Brow Bar, we provide the best and most affordable eyebrow threading, waxing, henna, and facial services in Suffern, NY'/>



            <div className="pa2 display dark-gray f1 tc mb3 h3">
                Our Work
            </div>
            <div className="flex flex-wrap center mw9 justify-around pb3">

                {data.cards.edges.map(({node}) => (
                    <Card
                        image={node.frontmatter.postImage.childImageSharp.fluid}
                    />
                ))}
            </div>

            <div
                className="h-auto bg-near-white mw9 w-100 flex flex-column items-center justify-center pv5 ph2 center">

                <span className='fw1 display ph2 pv1 dark-gray db tc w-100 mw7 f4'>
                    Visit our social media to see more of our work
                </span>
                <a href='https://fb.com/fusionbrowbar' target='_blank'
                   className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2">
                    Facebook ></a>
                <a rel="noopener noreferrer" href='https://instagram.com/fusionbrowbar' target='_blank'
                   className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2">Instagram ></a>

            </div>

            <div className='pa2 serif gray f4 tc mb3 h3'>
                <div><FaSearchLocation/></div>
                Located 15 minutes away from most places in Rockland county
            </div>
        </Layout>
    )
}

export const query = graphql`

  query {
    featuredPost: allMarkdownRemark(
      limit: 1,
      sort: {order: DESC, fields: frontmatter___date},
      filter: {frontmatter: {type: {eq: "post"}}}) {
      edges {
        node {
          frontmatter {
            title
            description: metaDescription
            slug
            postImage {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    cards: allMarkdownRemark(
      skip: 1,
      limit: 3,
      sort: {order: DESC, fields: frontmatter___date},
      filter: {frontmatter: {type: {eq: "post"}}}) {
      edges {
        node {
          frontmatter {
            title
            description: metaDescription
            slug
            postImage {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
      banner: file(relativePath: {eq: "img/people.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    site {
      siteMetadata {
        description
      }
    }
  }
`
